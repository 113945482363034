























































































































































































































































































































































































































import Vue from 'vue'
import Nav from '@/components/Nav.vue'
import { mapState, mapActions } from 'vuex'
import { ApiResponse, BankDeposit, Payment, Sale, Structure } from '@/interfaces'
import { DatePicker, Table } from 'element-ui'
import { invoiceTypes, paymentTypes } from '@/formHelpers'

interface DropdownAction {
  action: string
  payment?: Payment
}

interface lastNumberStructure {
  structureId: string
  lastNumber: string
}

interface BankAccount {
  value: string
  label: string
  structureId: number
}

export default Vue.extend({
  components: { Nav },
  data() {
    return {
      payments: [] as Payment[],
      paymentsToAssign: [] as number[],
      period: [] as string[],
      periodOptions: {
        shortcuts: [
          {
            text: 'Mois dernier',
            onClick(picker: DatePicker) {
              const start = new Date()
              const startMonth = start.getMonth() - 1
              start.setDate(1)
              start.setMonth(startMonth)
              const end = new Date(start.getFullYear(), startMonth + 1, 0)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: 'Mois en cours',
            onClick(picker: DatePicker) {
              const start = new Date()
              start.setDate(1)
              const end = new Date(start.getFullYear(), start.getMonth() + 1, 0)
              picker.$emit('pick', [start, end])
            },
          },
        ],
      },
      paymentTypeFilter: '',
      paymentTypes,
      invoiceTypes,
      invoiceTypeFilter: '',
      paymentTotalAmount: 0,
      bankDepositFilter: null as number | null,
      chosenBankDeposit: null as number | null,
      removeFromBankDeposit: false,
      bankDeposits: [],
      sales: [],
      saleFilter: null,
      showAssignDepositModal: false,
      showDepositModal: false,
      busy: false,
      allBankAccounts: [],
      bankAccounts: [],
      bankDepositFormData: {} as BankDeposit,
      bankDepositFormIndex: null as number | null,
      lastNumberByStructure: [] as lastNumberStructure[],
      structures: [] as Structure[],
      structureFilter: null as number | null,
      addons: 0,
    }
  },
  computed: {
    ...mapState(['user', 'invoicesFilters']),
  },
  watch: {
    user(newVal) {
      this.structures = newVal.client?.structures
      this.addons = newVal.client?.addons ?? 0
    },
  },
  mounted() {
    if (this.user) {
      this.addons = this.user.client?.addons ?? 0
    }
    if (this.$route.query.depositId) {
      this.bankDepositFilter = parseInt(this.$route.query.depositId as string)
    }

    if (
      this.invoicesFilters &&
      (this.invoicesFilters.previousRoute?.includes('payments') ||
        this.invoicesFilters.previousRoute?.includes('invoice_'))
    ) {
      this.setAccountingFilters()
    } else {
      this.setInvoicesFilters({})
    }

    if (this.user?.client?.structures) {
      this.structures = this.user?.client?.structures
    }
    this.fetchBankDeposits()
    this.fetchPayments()
    this.fetchBankAccounts()
    this.getSales()
  },
  methods: {
    ...mapActions(['setInvoicesFilters']),
    updateFilter() {
      this.setInvoicesFilters({
        sale: this.saleFilter,
        invoiceType: this.invoiceTypeFilter,
        paymentType: this.paymentTypeFilter,
        invoicingPeriod: this.period,
        bankDepositNumber: this.bankDepositFilter,
        structure: this.structureFilter,
        previousRoute: this.$route.name,
      })

      this.fetchPayments()
    },
    fetchPayments() {
      if (!this.busy) {
        const loading = this.$loading({
          target: '.payments-container',
          text: 'Chargement des données...',
        })
        this.$api
          .get(`/payments`, {
            params: {
              period: this.period,
              paymentType: this.paymentTypeFilter,
              sale: this.saleFilter,
              deposit: this.bankDepositFilter,
              invoiceType: this.invoiceTypeFilter,
              structure: this.structureFilter,
            },
          })
          .then((response) => {
            const apiResponse = response.data as ApiResponse

            this.payments = apiResponse.data
          })
          .catch(() => {
            this.$notify({
              type: 'error',
              title: 'Erreur',
              message: 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
            })
          })
          .finally(() => {
            loading.close()
            this.busy = false
          })
      }
    },

    fetchBankDeposits() {
      this.bankDeposits = []

      this.$api
        .get('/bank-deposits')
        .then((response) => {
          const apiResponse = response.data as ApiResponse

          this.bankDeposits = apiResponse.data.bankDeposits
          this.lastNumberByStructure = apiResponse.data.lastNumberByStructure
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            title: 'Erreur',
            message: 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
          })
        })
    },

    getSales() {
      this.$api
        .get(`/sales`, {
          params: {
            type: 'simple',
            pagination: false,
            sale: this.saleFilter,
          },
        })
        .then((response) => {
          const apiResponse = response.data as ApiResponse

          this.sales = apiResponse.data
        })
    },
    assignDeposit() {
      if (!this.busy) {
        this.busy = true

        const data = {
          ids: this.paymentsToAssign,
          bankDeposit: this.chosenBankDeposit,
          removeFromBankDeposit: this.removeFromBankDeposit ? 'NULL' : '',
          period: this.period,
          sale: this.saleFilter,
          paymentType: this.paymentTypeFilter,
          deposit: this.bankDepositFilter,
          invoiceType: this.invoiceTypeFilter,
        }

        const loading = this.$loading({
          target: '.payments-container',
          text: 'Chargement des données...',
        })
        this.$api
          .put(`/payments/mass-assign`, data)
          .then((response) => {
            const apiResponse = response.data as ApiResponse

            this.payments = apiResponse.data
            this.$notify({
              type: 'success',
              title: 'Succès',
              message: 'Modifications réalisées avec succès !',
            })
          })
          .catch((error) => {
            if (error.response) {
              const apiResponse = error.response.data as ApiResponse

              this.$notify({
                type: 'error',
                title: 'Erreur',
                message:
                  apiResponse.message ?? 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
              })
            }
          })
          .finally(() => {
            this.busy = false
            ;(this.$refs.paymentsTable as InstanceType<typeof Table>).clearSelection()
            loading.close()
            this.closeAssignDepositModal()
          })
      }
    },
    removeFromDeposit() {
      this.$confirm(
        'Êtes-vous sûr(e) de vouloir enlever les paiements de leurs remises ? ATTENTION, cette opération est irréversible.',
        'Confirmation',
        {
          confirmButtonText: 'OK',
          cancelButtonText: 'Annuler',
          type: 'warning',
        }
      ).then(() => {
        if (!this.busy) {
          this.busy = true

          const data = {
            ids: this.paymentsToAssign,
            removeFromBankDeposit: 'NULL',
            period: this.period,
            sale: this.saleFilter,
            paymentType: this.paymentTypeFilter,
            deposit: this.bankDepositFilter,
            invoiceType: this.invoiceTypeFilter,
          }

          const loading = this.$loading({
            target: '.payments-container',
            text: 'Traitement en cours...',
          })
          this.$api
            .put(`/payments/mass-assign`, data)
            .then((response) => {
              const apiResponse = response.data as ApiResponse

              this.payments = apiResponse.data
              this.$notify({
                type: 'success',
                title: 'Succès',
                message: 'Les paiements ont bien été enlevé de leurs remises',
              })
            })
            .catch((error) => {
              if (error.response) {
                const apiResponse = error.response.data as ApiResponse

                this.$notify({
                  type: 'error',
                  title: 'Erreur',
                  message:
                    apiResponse.message ?? 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
                })
              }
            })
            .finally(() => {
              this.busy = false
              ;(this.$refs.paymentsTable as InstanceType<typeof Table>).clearSelection()
              loading.close()
            })
        }
      })
    },
    handleSelectionChange(selectedPayments: Payment[]) {
      this.paymentsToAssign = []
      selectedPayments.forEach((payment) => {
        if (payment?.id) {
          this.paymentsToAssign?.push(payment.id)
        }
      })
      this.paymentTotalAmount = selectedPayments.reduce((acc, payment) => acc + parseFloat(payment.amount ?? ''), 0)
    },
    getPaymentSummary() {
      const paymentCount = this.paymentsToAssign?.length ?? 0
      return paymentCount + ' règlement(s) pour un total de ' + this.formatCurrency(this.paymentTotalAmount)
    },
    getDepositLabel(bankDeposit: BankDeposit) {
      let depositLabel = bankDeposit.structure?.name + ' - Remise n° ' + bankDeposit.depositNumber
      if (bankDeposit?.date) {
        depositLabel += ' - ' + this.formatDate(bankDeposit?.date?.toString())
      }
      depositLabel += ' - ' + bankDeposit.type
      return depositLabel
    },
    formatDate(str: string): string {
      const date = Date.parse(str)
      if (!isNaN(date)) {
        return new Intl.DateTimeFormat('fr-FR').format(date)
      } else {
        return '-'
      }
    },
    formatCurrency(amount: string | number) {
      if (typeof amount === 'string') {
        amount = parseFloat(amount) ?? 0
      }
      return Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(amount).toString()
    },
    getInvoiceNumber(payment: Payment) {
      return payment.invoice?.invoiceNumber ?? ''
    },
    getInvoiceContactName(payment: Payment) {
      return payment.invoice?.contactName ?? ''
    },
    getPaymentType(payment: Payment) {
      return this.paymentTypes.find((type) => type.value === payment.type)?.label ?? ''
    },
    editPaymentInvoice(payment: Payment) {
      this.$router.push({
        name: 'invoice_edit',
        params: {
          id: payment.invoice?.id?.toString() ?? '',
        },
      })
    },
    openDepositModal() {
      this.showDepositModal = true

      if (this.structureFilter) {
        this.updateBankDepositForm(this.structureFilter.toString())
      }
    },
    closeDepositModal() {
      this.showDepositModal = false
      this.chosenBankDeposit = null
    },
    openAssignDepositModal() {
      this.showAssignDepositModal = true
    },
    closeAssignDepositModal() {
      this.showAssignDepositModal = false
      this.removeFromBankDeposit = false
      this.chosenBankDeposit = null
    },
    handleItemCommand(command: DropdownAction) {
      // Assign payment to bank deposit
      if (command.action == 'massAssignDeposit') {
        this.openAssignDepositModal()
      }
      // Assign payment to bank deposit
      if (command.action == 'massDeposit') {
        this.openDepositModal()
      }
    },
    handleClose(done: CallableFunction) {
      this.closeAssignDepositModal()
      this.closeDepositModal()
      done()
    },
    getSaleTitle(sale: Sale): string {
      let saleTitle = ''
      if (sale?.title) {
        saleTitle += ' ' + sale?.title
      }
      if (sale?.startDate) {
        const date = Date.parse(sale?.startDate)
        if (date) {
          saleTitle += ' - ' + new Intl.DateTimeFormat('fr-FR').format(date)
        }
      }
      return saleTitle !== '' ? saleTitle : '-'
    },
    setAccountingFilters() {
      this.saleFilter = this.invoicesFilters?.sale
      this.paymentTypeFilter = this.invoicesFilters?.paymentType
      this.invoiceTypeFilter = this.invoicesFilters?.invoiceType
      this.bankDepositFilter = this.invoicesFilters?.bankDepositNumber
      this.period = this.invoicesFilters?.invoicingPeriod
    },
    invoiceNumberSort(a: Payment, b: Payment) {
      let aInvoiceNumber = ''
      let bInvoiceNumber = ''
      if (!a.invoice?.invoiceNumber) {
        return -1
      } else {
        aInvoiceNumber = a.invoice?.invoiceNumber.replace('-', '')
      }
      if (!b.invoice?.invoiceNumber) {
        return 1
      } else {
        bInvoiceNumber = b.invoice?.invoiceNumber.replace('-', '')
      }
      return aInvoiceNumber.localeCompare(bInvoiceNumber, undefined, { numeric: true, sensitivity: 'base' })
    },
    sortContactName(itemA: Payment, itemB: Payment) {
      if (!itemA.invoice?.contactName) return -1
      if (!itemB.invoice?.contactName) return 1
      return itemA.invoice?.contactName.toUpperCase().localeCompare(itemB.invoice?.contactName.toUpperCase())
    },
    amountSort(itemA: Payment, itemB: Payment) {
      const amountA = parseFloat(String(itemA.amount))
      const amountB = parseFloat(String(itemB.amount))
      if (amountA == amountB) {
        return 0
      }

      return amountA < amountB ? -1 : 1
    },
    getStructureColor(payment: Payment) {
      return payment?.invoice?.structure?.color
    },
    fetchBankAccounts() {
      this.allBankAccounts = []

      this.$api
        .get('/bank-accounts')
        .then((response) => {
          const apiResponse = response.data as ApiResponse

          this.allBankAccounts = apiResponse.data
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            title: 'Erreur',
            message: 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
          })
        })
    },
    submit() {
      const data = {
        newBankDeposit: this.bankDepositFormData,
        ids: this.paymentsToAssign,
        period: this.period,
        sale: this.saleFilter,
        paymentType: this.paymentTypeFilter,
        deposit: this.bankDepositFilter,
        invoiceType: this.invoiceTypeFilter,
      }
      const loading = this.$loading({
        target: '#bankDepositsTable',
        text: 'Chargement des données...',
      })

      if (!this.bankDepositFormData.id) {
        // Store
        this.$api
          .put('/payments/mass-assign', data)
          .then((response) => {
            const apiResponse = response.data as ApiResponse

            //this.bankDeposits.unshift(apiResponse.data.newBankDeposit)
            //this.lastNumberByStructure = apiResponse.data.lastNumberByStructure
            this.$notify({
              type: 'success',
              title: 'Succès',
              message: 'Remise en banque créée avec succès !',
            })
          })
          .catch((error) => {
            if (error.response) {
              const apiResponse = error.response.data as ApiResponse

              this.$notify({
                type: 'error',
                title: 'Erreur',
                message:
                  apiResponse.message ?? 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
              })
            }
          })
          .finally(() => {
            this.busy = false
            this.resetBankDepositFormData()
            this.closeDepositModal()
            this.fetchPayments()
            this.fetchBankDeposits()
            loading.close()
          })
      }
    },
    resetBankDepositFormData() {
      this.bankDepositFormData = {}
    },
    /*
    getBankDepositLastNumber(structureId: string) {
      this.$api
            .get(`/bank-deposit/lastNumber/${structureId}`)
            .then((response) => {
              const apiResponse = response.data as ApiResponse
              this.lastNumberByStructure = apiResponse.data.lastNumberByStructure
            })
            .catch((error) => {
              if (error.response) {
                const apiResponse = error.response.data as ApiResponse

                this.$notify({
                  type: 'error',
                  title: 'Erreur',
                  message:
                    apiResponse.message ?? 'Erreur dans la récupération du numéro de remise',
                })
              }
            })
            .finally(() => {
              this.busy = false
            })
    },
    */
    updateBankDepositForm(structureId: string) {
      let bankDepositChrono = this.lastNumberByStructure.find(
        (structure: lastNumberStructure) => structure.structureId === structureId
      )
      if (bankDepositChrono) {
        this.bankDepositFormData.depositNumber = parseInt(bankDepositChrono.lastNumber) + 1
      } else {
        this.bankDepositFormData.depositNumber = 1
      }
      if (this.structureFilter) {
        this.bankDepositFormData.structureId = this.structureFilter
      }
      if (this.paymentTypeFilter) {
        this.bankDepositFormData.type = this.paymentTypeFilter
      }
      //delete this.bankDepositFormData.bankAccount
      this.getBankAccount(structureId)
    },
    getBankAccount(structureId: string | null) {
      this.bankAccounts = this.allBankAccounts.filter(
        (bankAccount: BankAccount) => bankAccount.structureId.toString() === structureId
      )
    },
  },
})
